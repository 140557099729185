<template>
  <div class="about">
        <div class="about__padding about__container">
                <div class="about__image about__image-padding about__container">
                  <img src="@/assets/img/about_1.png" alt="about_1">
                  <img src="@/assets/img/about_2.png" alt="about_2">
                  <img src="@/assets/img/about_3.png" alt="about_3">
                </div>
                <div class="about__logo about__logo-padding">
                  <img src="@/assets/img/main_logo.svg" alt="main_logo">
                </div>
                <div class="about__title about__title-padding">
                  <h2>Про нас</h2>
                </div>
                <div class="about__text about__text-padding">
                  <div class="about__text-left">
                    <span><b>ГРОМАДСЬКА ОРГАНІЗАЦІЯ "АСОЦІАЦІЯ ВИРОБНИКІВ ЛІКАРСЬКИХ ТРАВ ТА РОСЛИН УКРАЇНИ"</b> 
                      є громадським об'єднанням, яка захищає права членів Організації, об'єднує на основі 
                      спільності інтересів до вирощування, збирання та обробки трав та рослин, для захисту 
                      своїх професійних, правових, соціальних, економічних, екологічних та інших прав шляхом
                       реалізації мети та завдань, передбачених організацією. Організація сприяє збереженню,
                        розвитку та популяризації українського села, збагачуючи його культурну спадщину. 
                        Діяльність Організації поширюється на всю територію України.
                      Організація надає повний супровід для експорту українських трав до Європи, включаючи 
                      консультаційну, інформаційну, правову, маркетингову та рекламну допомогу, сприяючи 
                      таким чином інтеграції українських виробників на міжнародні ринки.</span>
                  </div>
                  <div class="about__text-right">
                    <img src="@/assets/img/about_right.png" alt="about_right">
                  </div>
                </div>
                <div class="about__main about__main-padding">
                  <h2 >Основні цінності та принципи діяльності</h2>
                  <div class="about__main__ul">
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Захист прав членів організації</span>
                    </div>
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Єдність інтересів</span>
                    </div>
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Популяризація, розвиток та збереження українського села</span>
                    </div>
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Повний супровід для експорту українських трав до Європи</span>
                    </div>
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Екологічна безпека</span>
                    </div>
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Об'єднання зусиль</span>
                    </div>
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Координація діяльності</span>
                    </div>
                    <div class="about__main__ul-block">
                      <img src="@/assets/img/main_vector.svg" alt="main_vector">
                      <span>Єдина політика розвитку</span>
                    </div>
                  </div>
                </div>

        </div>
  </div>
</template>

<script setup>

</script>

<style>

</style>