<template>
  <div  class="header">
   <div class="header__content header__padding">
      <div class="header__top header__container">
        <div class="header__image">
          <img class="" src="../assets/img/header_logo.svg" alt="header_logo">
        </div>
        <div class="header__left">
        <ul class="header__menu">
          <li><router-link to="/">Головна</router-link></li>
          <li><a href="#about">Про нас</a></li>
      <li><a href="#info">Інформація</a></li>
      <li><a href="#footer">Контакти</a></li>
      </ul>
      <div class="header__lan-block">
        <router-link to="/eng"><img  src="@/assets/img/lan_eng.svg" alt="lan_eng"></router-link>
        <router-link to="/"><img src="@/assets/img/lan_ukr.svg" alt="lan_ukr"></router-link>
          </div>
      </div>
    </div>
    <div class="header__bottom header__container">
        <div class="header__title header__title__padding">
      <h2>ГО «АСОЦІАЦІЯ ВИРОБНИКІВ 
      ЛІКАРСЬКИХ ТРАВ ТА РОСЛИН УКРАЇНИ»</h2>
        </div>
      </div>
   </div>
  </div>
  </template>
<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/style.css";
</style>
