<template>
  <div id="app">
  <router-view/>
</div>
</template>

<script setup>

</script>
<style lang="scss">

</style>
