<template>
    <div id="info" class="info">
      <div class="info__container info__content info__content-padding">
          <h2>Information</h2>
          <div class="info__content__text">
          <p><b>The Organization</b> operates in accordance with the Constitution of Ukraine, the Civil and Tax Codes of Ukraine, the Law of Ukraine "On Public Associations," and other legal acts. The main principles of activity are voluntariness, self-governance, transparency, openness, and publicity. The Organization is non-commercial and does not aim to generate profit.</p>
          <p>Our logo features an image of an elderberry branch with black berries and green leaves on a background of a blue clover leaf with the inscription "Public Organization of Producers of Medicinal Herbs and Plants of Ukraine."</p>
          <p><b>The main goal of the Organization</b> is to unite the efforts of all interested parties to protect the rights and interests of its members, coordinate the production and sale of medicinal herbs and plants, promote their consumption, ensure environmental safety, and preserve natural resources.</p>
          <p><b>The Organization actively promotes</b> the creation of public associations of medicinal herb and plant producers in territorial communities, forming new units to attract as many participants as possible. We create favorable conditions for product realization both in the domestic market and abroad, assist in product promotion, and facilitate the exchange of knowledge and experience among members of the Organization. In cooperation with scientific and governmental institutions, we determine unified directions for the industry’s development, support the professional growth of producers, and provide consulting, informational, legal, marketing, and advertising assistance.</p>
          <p><b>The Association generalizes and disseminates</b> best practices in the production and processing of medicinal herbs, introducing new scientific and technical developments and methods. We create a favorable investment climate for specialized enterprises, assist in obtaining loans and attracting investments on preferential terms, and develop and implement programs for the proper storage of medicinal plants, crop insurance, and monitoring compliance with environmental standards.</p>
          <p><b>The Organization actively cooperates</b> with scientific institutions to enhance the database of medicinal plants, develops international cooperation through delegations and exchanges with other countries, and organizes scientific and practical conferences, exhibitions, roundtables, and themed competitions. We acquire property and non-property rights, sign agreements with residents and non-residents of Ukraine, organize events to fulfill statutory tasks, and establish separate divisions to implement these tasks. We spread knowledge about the use of medicinal herbs, promote organic farming, and support obtaining certifications for organic products, as well as developing green tourism in Ukraine.</p>
          <p><b>The Organization contributes to the preservation,</b> development, and popularization of Ukrainian villages, enriching their cultural heritage. We provide full support for the export of Ukrainian herbs to Europe, offering consulting, informational, legal, marketing, and advertising assistance.</p>
          <p><b>The Association of Medicinal Herb and Plant Producers of Ukraine</b> is a union that works for the benefit of our land and future generations.</p>
          </div>
      </div>
    </div>
  </template>
  
  <script setup>
  
  </script>
  
  <style>
  
  </style>