<template>
    <div id="about" class="about">
          <div class="about__padding about__container">
                  <div class="about__image about__image-padding about__container">
                    <img src="@/assets/img/about_1.png" alt="about_1">
                    <img src="@/assets/img/about_2.png" alt="about_2">
                    <img src="@/assets/img/about_3.png" alt="about_3">
                  </div>
                  <div class="about__logo about__logo-padding">
                    <img src="@/assets/img/main_logo.svg" alt="main_logo">
                  </div>
                  <div class="about__title about__title-padding">
                    <h2>About Us</h2>
                  </div>
                  <div class="about__text about__text-padding">
                    <div class="about__text-left">
                      <span><b>NON-GOVERNMENTAL ORGANIZATION  "ASSOCIATION OF PRODUCERS OF MEDICINAL HERBS AND PLANTS OF UKRAINE"</b> 
                        is a public association that protects the rights of its members. It unites individuals based on 
                        shared interests in cultivating, harvesting, and processing herbs and plants to protect their 
                        professional, legal, social, economic, ecological, and other rights through the implementation 
                        of the organization's goals and objectives. The organization promotes the preservation, development, 
                        and popularization of the Ukrainian countryside, enriching its cultural heritage. Its activities cover 
                        the entire territory of Ukraine.
                        The organization provides comprehensive support for the export of Ukrainian herbs to Europe, 
                        including consulting, informational, legal, marketing, and promotional assistance, thus facilitating 
                        the integration of Ukrainian producers into international markets.</span>
                    </div>
                    <div class="about__text-right">
                      <img src="@/assets/img/about_right.png" alt="about_right">
                    </div>
                  </div>
                  <div class="about__main about__main-padding">
                    <h2>Core Values and Principles</h2>
                    <div class="about__main__ul">
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>Protection of members' rights</span>
                      </div>
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>Unity of interests</span>
                      </div>
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>Popularization, development, and preservation of the Ukrainian countryside</span>
                      </div>
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>Comprehensive support for the export of Ukrainian herbs to Europe</span>
                      </div>
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>Environmental safety</span>
                      </div>
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>Pooling of efforts</span>
                      </div>
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>Coordination of activities</span>
                      </div>
                      <div class="about__main__ul-block">
                        <img src="@/assets/img/main_vector.svg" alt="main_vector">
                        <span>A unified development policy</span>
                      </div>
                    </div>
                  </div>
  
          </div>
    </div>
  </template>
  
  <script setup>
  
  </script>
  
  <style>
  
  </style>