<template>
    <div class="header">
     <div class="header__content header__padding">
        <div class="header__top header__container">
          <div class="header__image">
            <img class="" src="../assets/img/header_logo.svg" alt="header_logo">
          </div>
          <div class="header__left">
          <ul class="header__menu">
            <li>Home</li>
            <li><a href="#about_eng">About Us</a></li>
            <li><a href="#info_eng">Information</a></li>
            <li><a href="#footer_eng">Contacts</a></li>
          </ul>
          <div class="header__lan-block">
            <router-link to="/eng"><img  src="@/assets/img/lan_eng.svg" alt="lan_eng"></router-link>
          <router-link to="/"><img src="@/assets/img/lan_ukr.svg" alt="lan_ukr"></router-link>
          </div>
        </div>
      </div>
      <div class="header__bottom header__container">
          <div class="header__title header__title__padding">
        <h2>NON-GOVERNMENTAL ORGANIZATION  "ASSOCIATION OF PRODUCERS 
          OF MEDICINAL HERBS AND PLANTS OF UKRAINE".</h2>
          </div>
        </div>
     </div>
    </div>
  </template>
  
  <script setup>
  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  @import "@/assets/css/style.css";
  </style>