<template>
    <div id="#footer" class="footer">
        <div class="footer__content footer__container footer__padding">
            <h2>Contacts</h2>
            <div class="footer__flex">
            <div class="footer__content__left">
                <div class="footer__content-block footer__content-block-padding">
                    <div class="footer__content-text">
                        <img src="@/assets/img/footer_call.svg" alt="footer_call">
                        <a href="tel:+380669627170">380669627170</a> -
                        <span>Serhii Anatoliiovych Hubanov</span>
                    </div>
                    <div class="footer__content-text">
                        <span>Working hours: from <b>10</b> to <b>15</b></span>
                    </div>
                </div>
                <div class="footer__content-text footer_extra-padding">
                    <img src="@/assets/img/footer_message.svg" alt="footer_message">
                    <a href="mailto:asotsiatsiia.lik.trav.ukrainy@gmail.com">asotsiatsiia.lik.trav.ukrainy@gmail.com</a>
                </div>
                <div class="footer__content__left-bottom">
                    <span><b>Our address:</b></span>
                    <span>41854, Ukraine, Bilopillia District, Sumy Region, 
                        Mykolaivka (urban-type settlement), B. Svobody, Building 1a</span>
                </div>
            </div>
            <div class="footer__content__right">
                <img src="@/assets/img/footer_HS.svg" alt="footer_HS">
            </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
@import "@/assets/css/style.css";
</style>